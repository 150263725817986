import { Button, Col, Input, Row, Space, Typography, notification } from "antd";
import React, { useState } from "react";
import "./footer.scss";
import { useNavigate } from "react-router-dom";
const { Title, Text } = Typography;

const Footer = () => {
  const [email, setEmail] = useState('')
	const navigate = useNavigate()
  let countries = ["USA", "EUROPE", "GERMANY", "OTHERS"];

  let footerList = [
    // { label: "Career", link: "https://flixstock-talent.freshteam.com/jobs", type:'link'},
    { label: "FAQ", link: "" , type:''},
    { label: "Terms & Condition", link: "/terms-and-condition", type:'navigate' },
    { label: "Privacy Policy", link: "/policy", type:'navigate' },
  ];
  const footLinkHanlder =(data) =>{
    if(data.type == 'navigate'){
      navigate(data.link)
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
    else if(data.type == 'link'){
      var a = document.createElement("a");
      a.target = "_blank";
      a.href = data.link;
      a.click();
    }

  }
  const handleSubscribe =() =>{
    let pattern = /^(?!.*(?:''|\.\.))[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
    let finalObj={
      "email":email,
      identifier:'FlixStock'
    }
    if(pattern.test(email)){
      fetch("https://api-pegasus.flixstudio.io/limited/api/v1/inquiry/newSubscriptionAlert", {
        // Return promise
        method: "POST",
        // withCredentials: true,
        // credentials: 'include',
        body: JSON.stringify(finalObj),
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((res) => {
          if (res.status >= 200 && res.status < 300) {
            notification.success({
              message:"Request Successful",
            })
          }
        })
        .then(
          (result) => {},
          (error) => {
            console.log(error);
          }
        );
    }
    else{
      notification.info({
        message:"Error",
        description:"Please enter a valid email address."
      })
    }
  }
  return (
    <React.Fragment>
       <div className="common-container call_box fold-gap w-100 mx-auto">
        <Row>
          <Col
            xl={{ span: 10 }}
            sm={{ span: 24 }}
            className=" flexAlign-center text-left"
          >
            <Title level={3} className="call_box_title">
              Sign Up Now & Get a Call from Our Experts!
            </Title>
          </Col>
          <Col
            xl={{ span: 10, offset: 4 }}
            sm={{ span: 24 }}
            className="call_input_col flexAlign-center"
          >
            <Input
              className="input_call_box inner_call_box"
              placeholder="Enter email id"
            />
            <Button className="btn_call_box inner_call_box">
              Talk to Expert
            </Button>
          </Col>
        </Row>
      </div>
      <div className="px-4 fold-gap">
        <div className="hr_bar"></div>
      </div>
      <div className="company_info_box mt-4 ">
        <Row className="common-container company_row" gutter={[0, 24]}>
          <Col lg={6} xs={24}>
            <Space
              direction="vertical"
              align="start"
              className="info_under_logo"
              split
            >
              <img src="images/mainLogo.svg" />
              <Text className="text-left under_logo_text">
                "FlixStock offers a cutting-edge technology solution to help
                online sellers create high-quality product images for selling
                and marketing purposes"
              </Text>
            </Space>
          </Col>
          <Col lg={{ span: 4, offset: 8 }} xs={12}>
            <Space direction="vertical" align="start">
              <Title level={4} className="comp_mail comp_info_text">
                <strong>info@flixstock.com</strong>
              </Title>
              <Space align="start" direction="vertical" className="info_space">
                <Text>
                  <a className="link_text">Company</a>
                </Text>
                <Text>
                  <a className="link_text">About Us</a>
                </Text>
                <Text>
                  <a className="link_text">Partner with us</a>
                </Text>
                <Text>
                  <a className="link_text" onClick={()=> navigate('/contactus')}>Contact us</a>
                </Text>
                <Text>
                  <a className="link_text" href='https://careers.flixstock.com' >Career</a>
                </Text>
              </Space>
            </Space>
          </Col>
          <Col lg={{ span: 4, offset: 2 }} xs={12}>
            <Space direction="vertical" align="start">
              <Title level={4} className="comp_num comp_info_text">
                <strong>(917) 830-6172</strong>
              </Title>
              <Space align="start" direction="vertical" className="info_space">
                <Text>
                  <a className="link_text">Resources</a>
                </Text>
                <Text>
                  <a
                    href="https://blogs.flixstock.com/"
                    target="_blank"
                    className="link_text" rel="noreferrer"
                  >
                    Blog
                  </a>
                </Text>
                <Text>
                  <a className="link_text">News & Events</a>
                </Text>
                <Text>
                  <a className="link_text">Customer Story</a>
                </Text>
              </Space>
            </Space>
          </Col>
          <Col
            xl={{ span: 22, offset: 2 }}
            xs={{ span: 24, offset: 0 }}
            className="mt-1"
          >
            <Space direction="vertical" align="start" className="email_col">
              <label>Subscribe to our emails</label>
              <div className="d-flex email_div">
                <Input className="email_input" placeholder="Enter your email" onChange={(e)=>setEmail(e.target.value)} />
                <Button className="email_btn" onClick={()=>handleSubscribe()}>Subscribe</Button>
              </div>
            </Space>
          </Col>
          <Col span={24} className="d-flex country_box">
            <Space className="country_list" split>
              {countries.map((elm) => {
                return <Text className="country_text">{elm}</Text>;
              })}
            </Space>
            <div className="country_line"> </div>
          </Col>
          <Col lg={6} className="text-left mt-2">
            <Text className="address_text">
              E-commerce Product Photography, New York
              <br />
              Flat Lay Product Photography, New York
              <br />
              Ghost Mannequin Product Photography, New York
              <br />
              Clothing Product Photography, New York
            </Text>
          </Col>
          <Col
            lg={{ span: 6, offset: 3 }}
            md={{ span: 6, offset: 3 }}
            className="text-left mt-2"
          >
            <Text className="address_text">
              E-commerce Product Photography, Los Angeles
              <br />
              Flat Lay Product Photography, Los Angeles
              <br />
              Ghost Mannequin Product Photography, Los Angeles
              <br />
              Clothing Product Photography, Los Angeles
            </Text>
          </Col>
          <Col lg={{ span: 6, offset: 3 }} className="text-left mt-2">
            <Text className="address_text">
              E-commerce Product Photography, San Francisco
              <br />
              Flat Lay Product Photography, San Francisco
              <br />
              Ghost Mannequin Product Photography, San Francisco
              <br />
              Clothing Product Photography, San Francisco
            </Text>
          </Col>
        </Row>
      </div>

      <div className="footer w-100 common-container">
        <Space className="media_icon_container" split>
          <a
            href="https://www.linkedin.com/company/flixstock/mycompany/?viewAsMember=true"
            target="_blank" rel="noreferrer"
          >
            <img
              src="images/linkedin.png"
              alt="LinkedIn"
              className="social_media_icons"
            />
          </a>

          <a href="https://www.youtube.com/@flixstock1401" target="_blank" rel="noreferrer">
            <img
              src="images/youtube.png"
              alt="Youtube"
              className="social_media_icons"
            />
          </a>

          <a
            href="https://instagram.com/flixstudio.io?igshid=ZGUzMzM3NWJiOQ=="
            target="_blank" rel="noreferrer"
          >
            <img
              src="images/instagram.png"
              alt="Instagram"
              className="social_media_icons"
            />
          </a>
          {/* <img
						src="images/facebook.png"
						alt="Facebook"
						className="social_media_icons"
					/> */}
        </Space>
        <hr className="country_line" />
        <div className="footer_end flex-between mt-1 mb-1">
          <Space className="footer_list" split>
            {footerList.map((el) => {
              return <Text className="footer_list_text" onClick={()=> footLinkHanlder(el)}>{el.label}</Text>;
            })}
          </Space>
          <Text className="company_text">
            © FlixStock 2023 | All rights reserved
          </Text>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Footer;
